/* Basic Reset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

/* Ensure full width and height */
html, body {
    width: 100%;
    max-width: 100vw;
    height: 100%;
    margin: 0;
    padding: 0;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

}

body {
    font-size: 16px;
    line-height: 1.5;
    color: #333; /* A generic dark grey color, similar to many MUI themes */
    background-color: #FFFFFF;
    font-family: 'Lato', serif;
}

.hideOverflow {
    height: 100vh; /* Takes the full viewport height */
}